<template>
  <div class="container-page">
    <p class="title-inner">{{ id == 0 ? 'Создание' : 'Редактирование' }} вопроса</p>
    <div>
      <div class="row">
        <div class="form-group required col-2">
          <ComponentInput label="Номер вопроса" v-model="card.order" required type="number" />
        </div>
        <div class="form-group required">
          <ComponentInput
            label="Текст вопроса"
            v-model="card.questionText"
            placeholder="Введите текст вопроса"
            required
          />
        </div>
      </div>

      <div class="form-group required">
        <ComponentInput
          label="Ответ на вопрос"
          v-model="card.answer"
          placeholder="Введите текст ответа на вопрос"
          required
          type="textarea"
        />
      </div>

      <div class="form-group">
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isPublished" id="chPublished" />
          <label for="chPublished">Вопрос опубликован</label>
        </div>
      </div>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import Constants from '@/common/constants';

  import ComponentInput from '../../../common/components/ComponentInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import Api from '../api/question';

  export default {
    name: 'QuestionCard',
    components: { ButtonStock, ComponentInput },
    data() {
      return {
        id: this.$route.params.id,
        card: {
          id: 0,
        },
      };
    },
    created() {
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          Api.find(this.id).then((response) => {
            this.card = response.data;
          });
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        Api.update(this.card)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.card.id) {
              this.$router.push({ name: 'QuestionCard', params: { id: this.card.id, action: 'edit' } });
            } else {
              this.$router.push({ name: 'Questions' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Questions' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .stockTable {
    .catalog-icon {
      top: 18px;
    }
  }
</style>
