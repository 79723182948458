import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/question/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/question/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/question/${data.id}`, data);
    else return axios.post(`/api/question`, data);
  },
  delete: function (id) {
    return axios.delete(`/api/question/${id}`);
  },
};
